/*
 *	-- Components --
 *	Scripts concatenated and placed before document closing </body>
 */


// Globals
var project = project || {
		doc: {
			width: 0,
			height: 800
		},
		desktop: true,
		isTouch: false,
		viewChange: true
	},
	breakpoint = {
		tablet: 1000,
		portrait: 4 / 5,
		landscape: 5 / 4
	},
	storage = Storages.localStorage;

var twig = Twig.twig;

// Components
(function ($) {

	var changeIt = storage.get('changeIt') || { disclaimer: { accepted: false } };
	
	project.isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

	if (project.isTouch) {
		$('html').removeClass('no-touch').addClass('touch');
	}
	
	// import('components/_resize.js');
	// import('components/_modal.js');
	// import('components/_form.js');

	// import('components/_change-slide.js');
	// import('components/_nav.js');

	$(document).ready(function () {

		project.resize.init();
		project.modal.init();
		project.form.init();

		project.changeSlide.init();
		project.nav.init();
	});

})(jQuery);